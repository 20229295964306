/* @font-face {
  font-family: Aptos;
  src: url('./fonts/Microsoft/Aptos.ttf');
} */

/* @font-face {
  font-family: "Aptos"; 
  src: local("Aptos"),
    url("./fonts/Microsoft/Aptos-Light.ttf") format("ttf");
} */
@font-face {
  font-family: 'Aptos';
  src: url('./fonts/Microsoft/Aptos.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
body {
  margin: 0;
  font-family: "Titillium Web", sans-serif;
  font-weight: 200;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
body p{
  font-family: 'Aptos' !important;
  font-size: 18px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}