/* input focus */
textarea:focus, 
textarea.form-control:focus, 
input.form-control:focus, 
input[type=text]:focus, 
input[type=password]:focus, 
input[type=email]:focus, 
input[type=number]:focus, 
[type=text].form-control:focus, 
[type=password].form-control:focus, 
[type=email].form-control:focus, 
[type=tel].form-control:focus, 
[contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 #ddd;
}
/*  */

/*  */
.maincontainer{
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flexcenter {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .flexstart {
    display: flex;
    align-items: start;
    justify-content: start;
  }
  .flexend {
    display: flex;
    /* align-items: center; */
    justify-content: end;
  }
/*  */
.navbar{
    height: 100px !important;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: transparent !important;
}
.navbar h1{
    color: white;
}
/* home */

.topsection {
    position: relative;
    /* margin-top: 100px; */
    min-height: 100vh;
    color: white !important;
    /* background: linear-gradient(-45deg, rgba(254, 242, 85, 1) 0%, #cdccfe 51%, #ff9380); */
    background-image: url(./assets/background.webp) ;
    /* background: linear-gradient(-45deg, #4169E1 0%, #6687ea 51%, #4169E1); */
    
      /* background-size: 400% 400%; */
      /* animation: gradient 10s ease infinite; */
  }
  .hoverhomecontainer{
    position: absolute;
    height: 100vh;
    width: 100% !important;
  }

  .toppara{
    font-weight: 500;
  }
  /* blob buttons */

  .buttons {
    margin-top: 50px;
    text-align: center;
    border-radius: 30px;
  }
  .blob-btn {
    z-index: 1;
    position: relative;
    padding: 20px 46px;
    margin-bottom: 30px;
    text-align: center;
    text-transform: uppercase;
    color: white;
    font-size: 14px;
    font-weight: bold;
    background-color: transparent;
    outline: none;
    border: none;
    transition: color 0.5s;
    cursor: pointer;
    border-radius: 30px;
  }
  .blob-btn:before {
    content: "";
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 2px solid white;
    border-radius: 30px;
  }
  .blob-btn:after {
    content: "";
    z-index: -2;
    position: absolute;
    left: 3px;
    top: 3px;
    width: 100%;
    height: 100%;
    transition: all 0.3s 0.2s;
    border-radius: 30px;
  }
  .blob-btn:hover {
    color: black;
    border-radius: 30px;
  }
  .blob-btn:hover:after {
    transition: all 0.3s;
    left: 0;
    top: 0;
    border-radius: 30px;
  }
  .blob-btn__inner {
    z-index: -1;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    background: #ffffff00;
  }
  .blob-btn__blobs {
    position: relative;
    display: block;
    height: 100%;
    filter: url('#goo');
  }
  .blob-btn__blob {
    position: absolute;
    top: 2px;
    width: 25%;
    height: 100%;
    background: white;
    border-radius: 100%;
    transform: translate3d(0, 150%, 0) scale(1.7);
    transition: transform 0.45s;
  }
  /* @supports (filter: url('#goo')) 
  {
    transform: translate3d(0, 150%, 0) scale(1.4);
  } */
  .blob-btn__blob:nth-child(1) {
    left: 0%;
    transition-delay: 0s;
  }
  .blob-btn__blob:nth-child(2) {
    left: 30%;
    transition-delay: 0.08s;
  }
  .blob-btn__blob:nth-child(3) {
    left: 60%;
    transition-delay: 0.16s;
  }
  .blob-btn__blob:nth-child(4) {
    left: 90%;
    transition-delay: 0.24s;
  }
  .blob-btn:hover .blob-btn__blob {
    transform: translateZ(0) scale(1.7);
  }
  /* side nav */

  .sidenav {
    height: 100vh;
    width: 100%;
    opacity: 0%;
    /* display: none; */
    display: none;
    /* border-radius: 0% 0% 100% 100%; */
    position: fixed;
    /* position: relative; */
    z-index: 92;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .85);
    
    /* background: linear-gradient(0deg, rgba(240, 231, 252, 1) 0%, #4169E1 100%); */
    /* background: linear-gradient(0deg, rgba(240, 231, 252, 1) 0%, rgba(205, 204, 254, 1) 100%); */
    overflow: hidden;
    /* padding-top: 60px;  */
    transition: 1s ease-in-out;
  }
  .Sidenavlinks {
    padding: 8px 8px 8px 8px;
    text-decoration: none;
    font-size: 25px;
    color: rgb(255, 255, 255);
    display: block;
    transition: 0.3s;
    text-align: center;
    font-weight: bold;
  }
  .Sidenavlinks:hover{
      color: #4169E1;
  }
  
  .sidenavlinksactive {
    color: #4169E1 !important;
  }
  .sidenavopen{
    height: 0px;
    overflow: hidden;
    transition: .4s ease-in-out;
  }
  .arrow {
    border: solid rgb(255, 255, 255);
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 4px;
    margin-left: 0px;
    margin-bottom: 5px;
    transition: .4s ease-in-out;
    transform: rotate(45deg);
  }
  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(225deg);
  }

  /* hamburger */
  /* hamburger */
.hamburger {
    /* position: fixed !important; */
    z-index: 94;
    width: 4rem;
    height: 4rem;
    top: 1rem;
    right: 3rem;
    cursor: pointer;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-right: 10px;
  }
  
  
  .line {
    width: 100%;
    height: 0.2rem;
    background-color:rgb(255, 255, 255);
    /* border-radius: 0.2rem; */
    transition: all 0.5s ease-in-out;
  }
  
  .clicked .line-1 {
    transform: rotateZ(-405deg) translate(-0.4rem, 0.1rem);
    background-color: rgb(255, 255, 255);
  }
  
  .clicked .line-2 {
    opacity: 0;
    background-color: rgb(255, 255, 255);
  }
  
  .clicked .line-3 {
    transform: rotateZ(405deg) translate(-0.8rem, -0.6rem);
    background-color: rgb(255, 255, 255);
  }
  .ourfocussection{
    min-height: 80vh;
  }
  .servicescontainer{
    margin-top: 50px;
  }
  .servicescontainer h3{
    font-size: 24px !important;
  }
  .servicescontainer p{
    font-size: 16px !important;
    text-align: center;
  }
  /*  */
  .box-item {
    position: relative;
    -webkit-backface-visibility: hidden;
    width: 415px;
    margin-bottom: 35px;
    max-width: 100%;
  }
  
  .flip-box {
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    perspective: 1000px;
    -webkit-perspective: 1000px;
  }
  .flip-box h3{
    font-size: 30px !important;
  }
  .flip-box p{
    font-size: 18px !important;
  }
  
  .flip-box-front,
  .flip-box-back {
    background-color: #4169E1;
    color:white;
    background-size: cover;
    background-position: center;
    font-weight: bold;
    /* border-radius: 8px; */
    min-height: 300px;
    -ms-transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
    transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
    -webkit-transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-box-front {
    -ms-transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  
  .flip-box:hover .flip-box-front {
    -ms-transform: rotateY(-180deg);
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  
  .flip-box-back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #181818;
    
    -ms-transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  
  .flip-box:hover .flip-box-back {
    -ms-transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  
  .flip-box .inner {
    position: absolute;
    left: 0;
    width: 100%;
    padding: 20px;
    outline: 1px solid transparent;
    -webkit-perspective: inherit;
    perspective: inherit;
    z-index: 2;
    
    transform: translateY(-50%) translateZ(60px) scale(.94);
    -webkit-transform: translateY(-50%) translateZ(60px) scale(.94);
    -ms-transform: translateY(-50%) translateZ(60px) scale(.94);
    top: 50%;
  }
  
  .flip-box-header {
    font-size: 34px;
  }
  
  .flip-box p {
    font-size: 20px;
    line-height: 1.5em;
  }
  
  .flip-box-img {
    margin-top: 25px;
  }
  
  .flip-box-button {
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    margin-top: 25px;
    padding: 15px 20px;
    text-transform: uppercase;
  }
  /*  */
  .headtext{
    color: #444444;
    font-size: 40px;
    font-weight: bold;
  }
  .headtext2{
    color: #444444;
    font-size: 30px;
    /* font-weight: bold; */
  }
  .button1{
    border: 1px solid white;
    color: white;
    background-color: transparent;
    padding: 10px 25px 10px 25px;
    font-size: 18px;
    transition: .3s ease-in-out;
  }
  .button1:hover{
    background-color: #0057FF;
    border: 1px solid #0057FF;
  }

  /*  */
  .button2{
    border: 1px solid #0057FF;
    color: black;
    background-color: transparent;
    padding: 10px 25px 10px 25px;
    font-size: 18px;
    transition: .3s ease-in-out;
  }
  .button2:hover{
    color: white;
    background-color: #0057FF;
  }
  .button3{
    border: 1px solid white;
    color: white;
    background-color: transparent;
    padding: 10px 25px 10px 25px;
    font-size: 18px;
    transition: .3s ease-in-out;
  }
  .button3:hover{
    background-color: white;
    color: black;
  }
  .ecosyssec{
    min-height: 40vh;
    background: rgb(45,142,245);
    background: linear-gradient(90deg, rgba(45,142,245,1) 0%, rgba(1,90,254,1) 100%);
    padding: 50px;
  }
  .ecosyscontain{

  }
  .ecosyscontain h1{
    color: white;
    font-weight: bold;
    font-size: 62px;
  }
  .ecosyscontain p{
    color: white;
    font-size: 18px;
  }
  .sitefooter{
    min-height: 350px;
    display: flex;
   flex-direction: column;
   border-top: 1px solid rgb(53, 53, 53);
   /* margin-top: 100px; */
   
  }

  .foottopcontain{
    color: black;
  }
  .foottopcontain p{
    padding-top: 20px;
    margin-bottom: 0px !important;
  }
  .footbotcontain{
    background-color: #0057FF;
    color: white;
  }
  .foottopcontain, .footbotcontain{
    flex: 1;
  }
  /* contact */
  .pagenamesection{
    padding-top: 120px;
    height: 50vh;
    background-image: url('./assets/6903346.webp');
    background-size: cover;
    color: white;
  }
  .pagenamesection h2{
    font-weight: bold;
    
  }
  .loadercontain{
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.464);
    /* backdrop-filter: blur(1px); */
  }
  .loader {
    margin: auto;
    border: 3px solid #EAF0F6;
    border-radius: 50%;
    border-top: 3px solid #6759ff;
    width: 50px;
    height: 50px;
    animation: spinner 4s linear infinite;
  }
  .formcontainer {
    max-width: 400px;
    position: relative;
  }
  textarea{
    resize: none;
  }
  .mapcontainer iframe{
    width: 100%;
    min-height: 300px ;
  }
  .servicepagecontain{
    max-width: 1100px;
    border: 1px solid #D8D8D8;
    border-radius: 5px;
  }
  .servicepagebotcon{
    border-top:  1px solid #D8D8D8;
    background-color: white;
  }
  .edgemainsection{
    background-color: #F8F9FA;
  }
  @keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .textjustify{
    text-align: justify;
  }
  /* media query */
  @media only screen and (max-width:600px) {

    .headtext{
        font-size: 25px;
      }
      .ecosyscontain h1{
        font-size: 28px;
      }
      .ecosyscontain p{
        color: white;
        font-size: 18px;
      }
      .topsection{
        /* padding-top: 10px; */
        min-height: 110vh;
      }
      .homerow{
        flex-direction: column-reverse;
      }
      .contactrow{
        flex-direction: column-reverse;
      }
  }